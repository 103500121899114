import Axios from "axios";

const isLocal = false;

const localAPIurl = "http://localhost:44390/";
const liveURL = "https://api.codexroyale.com/";

const urlInUse = isLocal? localAPIurl : liveURL;

let headers = {};
   
   if (localStorage.user){
   headers.Authorization = `bearer ${localStorage.user['token']}`;
   }
   export const axios = Axios.create({baseURL:urlInUse, headers,});